import { StaticImageData } from "next/image";
import companyLogo_stripeSvg from "public/companies/stripe.svg";
import eventPeopleWithSpeakerPng from "public/event-people-with-speaker.png";
import floorplan_noTagsSvg from "public/floorplan/no-tags.svg";
import floorsSvg from "public/floors.svg";
import host_gableCalendarSyncPng from "public/host/gable-calendar-sync.png";
import host_gableNexudusExchangePng from "public/host/gable-nexudus-exchange.png";
import host_gableOfficerndExchangePng from "public/host/gable-officernd-exchange.png";
import host_googleCalendarHeaderSvg from "public/host/google-calendar-header.svg";
import host_googleCalendarPng from "public/host/google-calendar.png";
import host_officeRndSvg from "public/host/officernd.svg";
import hourBookingControlSvg from "public/hour-booking-control.svg";
import icons_assignBookingSvg from "public/icons/assign-booking.svg";
import icons_balloonsWithMagnifyingGlassSvg from "public/icons/balloons-with-magnifying-glass.svg";
import icons_baloonsPurpleSvg from "public/icons/baloons-purple.svg";
import icons_blackPieChartWithDollarSvg from "public/icons/black-pie-chart-with-dollar.svg";
import icons_blackTreeNodesSvg from "public/icons/black-tree-nodes.svg";
import icons_blackUserAvatarSvg from "public/icons/black-user-avatar.svg";
import icons_brivoPng from "public/icons/brivo.png";
import icons_buildingsWithRadiusSvg from "public/icons/buildings-with-radius.svg";
import icons_businessMeetingPurpleSvg from "public/icons/business-meeting-purple.svg";
import icons_businessMeetingSvg from "public/icons/business-meeting.svg";
import icons_chainLinkSvg from "public/icons/chain-link.svg";
import icons_circledCheckmarkSvg from "public/icons/circled-checkmark.svg";
import icons_cogWheelSvg from "public/icons/cog-wheel.svg";
import icons_departmentIcons_lightbulbSvg from "public/icons/department-icons/lightbulb.svg";
import icons_departmentIcons_treeNodesSvg from "public/icons/department-icons/tree-nodes.svg";
import icons_employeeVisitSvg from "public/icons/employee-visit.svg";
import icons_eventSpacePinkSvg from "public/icons/event-space-pink.svg";
import icons_foldedMapWithGablesSvg from "public/icons/folded-map-with-gables.svg";
import icons_formChecksSvg from "public/icons/form-checks.svg";
import icons_gableHouseVioletDoorSvg from "public/icons/gable-house-violet-door.svg";
import icons_gableSpaceColorfulSvg from "public/icons/gable-space-colorful.svg";
import icons_gableWithQuestionMarkColorfulSvg from "public/icons/gable-with-question-mark-colorful.svg";
import icons_globeSvg from "public/icons/globe.svg";
import icons_handsClashSvg from "public/icons/hands-clash.svg";
import icons_handshakeOrangeSvg from "public/icons/handshake-orange.svg";
import icons_homeProgressBarSvg from "public/icons/home-progress-bar.svg";
import icons_housePurpleCheckMarkSvg from "public/icons/house-purple-check-mark.svg";
import icons_incognitoFaceSvg from "public/icons/incognito-face.svg";
import icons_interviewPurpleSvg from "public/icons/interview-purple.svg";
import icons_interviewSvg from "public/icons/interview.svg";
import icons_keySvg from "public/icons/key.svg";
import icons_legalDocumentSvg from "public/icons/legal-document.svg";
import icons_locationGridSvg from "public/icons/location-grid.svg";
import icons_locationTransparentSvg from "public/icons/location-transparent.svg";
import icons_locationsInOrbsPng from "public/icons/locations-in-orbs.png";
import icons_magnifyingGlassHappySvg from "public/icons/magnifying-glass-happy.svg";
import icons_magnifyingGlassSvg from "public/icons/magnifying-glass.svg";
import icons_mapMelonTeamClusterSvg from "public/icons/map-melon-team-cluster.svg";
import icons_mapMelonTeamSvg from "public/icons/map-melon-team.svg";
import icons_mapPurpleTeamClusterSvg from "public/icons/map-purple-team-cluster.svg";
import icons_mapPurpleTeamSvg from "public/icons/map-purple-team.svg";
import icons_markerWithStarLilacSvg from "public/icons/marker-with-star-lilac.svg";
import icons_markerWithStarPlusPurpleSvg from "public/icons/marker-with-star-plus-purple.svg";
import icons_meetingRoomMelonSvg from "public/icons/meeting-room-melon.svg";
import icons_moneyBagBlackSvg from "public/icons/money-bag-black.svg";
import icons_moneyBagWithFormSvg from "public/icons/money-bag-with-form.svg";
import icons_mountainSceneryLightPurpleSvg from "public/icons/mountain-scenery-light-purple.svg";
import icons_oneEyeCalendarBlackSvg from "public/icons/one-eye-calendar-black.svg";
import icons_oneEyeCalendarBulkyWhiteFillSvg from "public/icons/one-eye-calendar-bulky-white-fill.svg";
import icons_oneEyeCalendarInactiveSvg from "public/icons/one-eye-calendar-inactive.svg";
import icons_oneEyeCalendarWhiteFillSvg from "public/icons/one-eye-calendar-white-fill.svg";
import icons_oneEyeCalendarSvg from "public/icons/one-eye-calendar.svg";
import icons_openEyeCrossedOutSvg from "public/icons/open-eye-crossed-out.svg";
import icons_openEyeSvg from "public/icons/open-eye.svg";
import icons_partialPieChartSvg from "public/icons/partial-pie-chart.svg";
import icons_pencilSvg from "public/icons/pencil.svg";
import icons_privateOfficeVioletSvg from "public/icons/private-office-violet.svg";
import icons_purpleCommonHouseSvg from "public/icons/purple-common-house.svg";
import icons_purplePieChartSvg from "public/icons/purple-pie-chart.svg";
import icons_paymentsIconSvg from "public/icons/settingscards/payments-icon.svg";
import icons_shareCopyLinkSvg from "public/icons/share/copy-link.svg";
import icons_shareTwitterSvg from "public/icons/share/twitter.svg";
import icons_shieldIconWhiteSvg from "public/icons/shield-icon-white.svg";
import icons_singleSeatSvg from "public/icons/single-seat.svg";
import icons_slackLogoSvg from "public/icons/slack-logo.svg";
import icons_smartphoneSvg from "public/icons/smartphone.svg";
import icons_smileyPurpleSvg from "public/icons/smiley-purple.svg";
import icons_smileyWithGableMapIconSvg from "public/icons/smiley-with-gable-map-icon.svg";
import icons_smileyWithHatSvg from "public/icons/smiley-with-hat.svg";
import icons_socialVisitPurpleSvg from "public/icons/social-visit-purple.svg";
import icons_spaceCardWithHeartIconSvg from "public/icons/space-card-with-heart-icon.svg";
import icons_squaresWithWarningSvg from "public/icons/squares-with-warning.svg";
import icons_swapSquaresSvg from "public/icons/swap-squares.svg";
import icons_threeCheckmarksPurpleSvg from "public/icons/three-checkmarks-purple.svg";
import icons_threeDotsPinkSvg from "public/icons/three-dots-pink.svg";
import icons_threeFormsWithStarSvg from "public/icons/three-forms-with-star.svg";
import icons_threeStackedSquaresPurpleSvg from "public/icons/three-stacked-squares-purple.svg";
import icons_trashCanSvg from "public/icons/trash-can.svg";
import icons_vendorVisitSvg from "public/icons/vendor-visit.svg";
import icons_verkadaSvg from "public/icons/verkada.svg";
import icons_viewListingIconSvg from "public/icons/view_listing_icon.svg";
import icons_visitFormSvg from "public/icons/visit-form.svg";
import icons_visitorManagementBuilding from "public/icons/visitor-management/building-black-purple.svg";
import icons_cameraSvg from "public/icons/visitor-management/camera.svg";
import icons_contactCardSvg from "public/icons/visitor-management/contact-card.svg";
import icons_visitManagementDocument from "public/icons/visitor-management/document.svg";
import icons_visitManagementGableInSwimmingRing from "public/icons/visitor-management/gable-in-swimming-ring.svg";
import icons_visitorManagementGableOrchestra from "public/icons/visitor-management/gable-orchestra.svg";
import icons_visitManagementHello from "public/icons/visitor-management/hello.svg";
import icons_visitorManagementHourglassTimer from "public/icons/visitor-management/hourglass-timer.svg";
import icons_visitorManagementInviteSuccess from "public/icons/visitor-management/invite-success.svg";
import icons_visitorManagementNewInvite from "public/icons/visitor-management/new-invite.svg";
import icons_officeSingleBuildingSvg from "public/icons/visitor-management/office-single-building.svg";
import icons_visitorManagementPeople from "public/icons/visitor-management/people.svg";
import icons_phoneSvg from "public/icons/visitor-management/phone.svg";
import icons_visitorManagementPrinter from "public/icons/visitor-management/printer.svg";
import icons_visitorManagementPurpleHeart from "public/icons/visitor-management/purple-heart.svg";
import icons_visitorManagementQr from "public/icons/visitor-management/qr.svg";
import icons_visitorManagementReception from "public/icons/visitor-management/reception.svg";
import icons_visitorManagementVisitorImagePreview from "public/icons/visitor-management/grey-gable.svg";
import icons_gableCombinationLogo from "public/icons/gable-combination-logo.svg";
import icons_SmileyWithCapSvg from "public/icons/visitor-management/smiley-with-cap.svg";
import icons_visitorManagementVisitCard from "public/icons/visitor-management/visit-card.svg";
import icons_visitorManagementVisitPointers from "public/icons/visitor-management/visit-pointers.svg";
import icons_visitorSignInSuccessSvg from "public/icons/visitor-sign-in-success.svg";
import icons_visitorSignInSvg from "public/icons/visitor-sign-in.svg";
import icons_worldCircledBySmiliesSvg from "public/icons/world-circled-by-smilies.svg";
import lightbulbSvg from "public/lightbulb.svg";
import marketing_companyBudgetDashboardPng from "public/marketing/company-budget-dashboard.png";
import timeControlSvg from "public/time-control.svg";

import marketing_visitorManagementPng from "public/marketing/visitor-management.png";
import officeColorfulSvg from "public/office-colorful.svg";
import officeGraySvg from "public/office-gray.svg";
import officePurpleSvg from "public/office-purple.svg";
import officeWhiteSvg from "public/office-white.svg";
import okHandSvg from "public/ok-hand.svg";
import icons_publicApiColorSvg from "public/public-api-icon-colors.svg";
import icons_publicApiSvg from "public/public-api-icon.svg";
import rippling_fullLogoBlackPng from "public/rippling/full_logo_black.png";
import rippling_rrrIconPng from "public/rippling/rrr_icon.png";
import spaceCardsCarouselPng from "public/space-cards-carousel.png";
import spaceCardsTallWaterfallPng from "public/space-cards-tall-waterfall.png";
import spaceCardsWaterfallPng from "public/space-cards-waterfall.png";
import welcome_explorerWithCatSvg from "public/welcome/explorer-with-cat.svg";
import welcome_twoExplorersSvg from "public/welcome/two-explorers.svg";

import listSpaceSvg from "public/host/list-space.svg";
import unlistSpaceSvg from "public/host/unlist-space.svg";

import icons_draftSvg from "public/icons/draft.svg";
import mapPins_CommonSpace_clusterSvg from "public/map-pins/CommonSpace/cluster.svg";
import mapPins_CommonSpace_clusterUnavailableSvg from "public/map-pins/CommonSpace/cluster_unavailable.svg";
import mapPins_CommonSpace_hotSvg from "public/map-pins/CommonSpace/hot.svg";
import mapPins_CommonSpace_newSvg from "public/map-pins/CommonSpace/new.svg";
import mapPins_CommonSpace_simpleSvg from "public/map-pins/CommonSpace/simple.svg";
import mapPins_CommonSpace_simpleUnavailableSvg from "public/map-pins/CommonSpace/simple_unavailable.svg";
import mapPins_CompanyOffice_clusterSvg from "public/map-pins/CompanyOffice/cluster.svg";
import mapPins_CompanyOffice_clusterUnavailableSvg from "public/map-pins/CompanyOffice/cluster_unavailable.svg";
import mapPins_CompanyOffice_hotSvg from "public/map-pins/CompanyOffice/hot.svg";
import mapPins_CompanyOffice_newSvg from "public/map-pins/CompanyOffice/new.svg";
import mapPins_CompanyOffice_simpleSvg from "public/map-pins/CompanyOffice/simple.svg";
import mapPins_CompanyOffice_simpleUnavailableSvg from "public/map-pins/CompanyOffice/simple_unavailable.svg";
import mapPins_darkDotSvg from "public/map-pins/dark_dot.svg";
import mapPins_EventSpace_clusterSvg from "public/map-pins/EventSpace/cluster.svg";
import mapPins_EventSpace_clusterUnavailableSvg from "public/map-pins/EventSpace/cluster_unavailable.svg";
import mapPins_EventSpace_hotSvg from "public/map-pins/EventSpace/hot.svg";
import mapPins_EventSpace_newSvg from "public/map-pins/EventSpace/new.svg";
import mapPins_EventSpace_simpleSvg from "public/map-pins/EventSpace/simple.svg";
import mapPins_EventSpace_simpleUnavailableSvg from "public/map-pins/EventSpace/simple_unavailable.svg";
import mapPins_Generic_clusterSvg from "public/map-pins/Generic/cluster.svg";
import mapPins_Generic_clusterUnavailableSvg from "public/map-pins/Generic/cluster_unavailable.svg";
import mapPins_Generic_simpleSvg from "public/map-pins/Generic/simple.svg";
import mapPins_Generic_simpleUnavailableSvg from "public/map-pins/Generic/simple_unavailable.svg";
import mapPins_MeetingRoom_clusterSvg from "public/map-pins/MeetingRoom/cluster.svg";
import mapPins_MeetingRoom_clusterUnavailableSvg from "public/map-pins/MeetingRoom/cluster_unavailable.svg";
import mapPins_MeetingRoom_hotSvg from "public/map-pins/MeetingRoom/hot.svg";
import mapPins_MeetingRoom_newSvg from "public/map-pins/MeetingRoom/new.svg";
import mapPins_MeetingRoom_simpleSvg from "public/map-pins/MeetingRoom/simple.svg";
import mapPins_MeetingRoom_simpleUnavailableSvg from "public/map-pins/MeetingRoom/simple_unavailable.svg";
import mapPins_PrivateOffice_clusterSvg from "public/map-pins/PrivateOffice/cluster.svg";
import mapPins_PrivateOffice_clusterUnavailableSvg from "public/map-pins/PrivateOffice/cluster_unavailable.svg";
import mapPins_PrivateOffice_hotSvg from "public/map-pins/PrivateOffice/hot.svg";
import mapPins_PrivateOffice_newSvg from "public/map-pins/PrivateOffice/new.svg";
import mapPins_PrivateOffice_simpleSvg from "public/map-pins/PrivateOffice/simple.svg";
import mapPins_PrivateOffice_simpleUnavailableSvg from "public/map-pins/PrivateOffice/simple_unavailable.svg";

const SVG_INTERNAL = {
  host_officeRnd: host_officeRndSvg,
  host_googleCalendarHeader: host_googleCalendarHeaderSvg,
  floors: floorsSvg,
  timeControl: timeControlSvg,
  hourBookingControl: hourBookingControlSvg,
  lightbulb: lightbulbSvg,
  officeColorful: officeColorfulSvg,
  officeGray: officeGraySvg,
  floorplan_noTags: floorplan_noTagsSvg,
  icons_pencil: icons_pencilSvg,
  icons_trashCan: icons_trashCanSvg,
  officePurple: officePurpleSvg,
  officeWhite: officeWhiteSvg,
  icons_shareTwitter: icons_shareTwitterSvg,
  icons_shareCopyLink: icons_shareCopyLinkSvg,
  icons_smileyPurple: icons_smileyPurpleSvg,
  icons_mapMelonTeamCluster: icons_mapMelonTeamClusterSvg,
  icons_mapMelonTeam: icons_mapMelonTeamSvg,
  icons_mapPurpleTeamCluster: icons_mapPurpleTeamClusterSvg,
  icons_mapPurpleTeam: icons_mapPurpleTeamSvg,
  icons_markerWithStarLilac: icons_markerWithStarLilacSvg,
  icons_markerWithStarPlusPurple: icons_markerWithStarPlusPurpleSvg,
  icons_chainLink: icons_chainLinkSvg,
  companyLogo_stripe: companyLogo_stripeSvg,
  welcome_explorerWithCat: welcome_explorerWithCatSvg,
  welcome_twoExplorers: welcome_twoExplorersSvg,
  okHand: okHandSvg,
  icons_publicApiSvg: icons_publicApiSvg,
  icons_publicApiColorSvg: icons_publicApiColorSvg,
  icons_magnifyingGlass: icons_magnifyingGlassSvg,
  icons_magnifyingGlassHappy: icons_magnifyingGlassHappySvg,
  icons_worldCircledBySmilies: icons_worldCircledBySmiliesSvg,
  icons_mountainSceneryLightPurple: icons_mountainSceneryLightPurpleSvg,
  icons_handsClash: icons_handsClashSvg,
  icons_handshakeOrange: icons_handshakeOrangeSvg,
  icons_threeDotsPink: icons_threeDotsPinkSvg,
  icons_departmentIcons_treeNodes: icons_departmentIcons_treeNodesSvg,
  icons_departmentIcons_lightbulb: icons_departmentIcons_lightbulbSvg,
  icons_oneEyeCalendar: icons_oneEyeCalendarSvg,
  icons_oneEyeCalendarInactive: icons_oneEyeCalendarInactiveSvg,
  icons_oneEyeCalendarBlack: icons_oneEyeCalendarBlackSvg,
  icons_meetingRoomMelon: icons_meetingRoomMelonSvg,
  icons_purpleCommonHouse: icons_purpleCommonHouseSvg,
  icons_privateOfficeViolet: icons_privateOfficeVioletSvg,
  icons_eventSpacePink: icons_eventSpacePinkSvg,
  icons_businessMeeting: icons_businessMeetingSvg,
  icons_businessMeetingPurple: icons_businessMeetingPurpleSvg,
  icons_socialVisitPurple: icons_socialVisitPurpleSvg,
  icons_interview: icons_interviewSvg,
  icons_interviewPurple: icons_interviewPurpleSvg,
  icons_threeCheckmarksPurple: icons_threeCheckmarksPurpleSvg,
  icons_vendorVisit: icons_vendorVisitSvg,
  icons_viewListingIcon: icons_viewListingIconSvg,
  icons_employeeVisit: icons_employeeVisitSvg,
  icons_visitorSignIn: icons_visitorSignInSvg,
  icons_visitorSignInSuccess: icons_visitorSignInSuccessSvg,
  icons_baloonsPurple: icons_baloonsPurpleSvg,
  icons_smileyWithHat: icons_smileyWithHatSvg,
  icons_formChecks: icons_formChecksSvg,
  icons_legalDocument: icons_legalDocumentSvg,
  icons_visitForm: icons_visitFormSvg,
  icons_smartphone: icons_smartphoneSvg,
  icons_moneyBagBlack: icons_moneyBagBlackSvg,
  icons_blackPieChartWithDollar: icons_blackPieChartWithDollarSvg,
  icons_visitorManagementBuilding: icons_visitorManagementBuilding,
  icons_visitorManagementPrinter: icons_visitorManagementPrinter,
  icons_visitorManagementInviteSuccess: icons_visitorManagementInviteSuccess,
  icons_visitManagementHello: icons_visitManagementHello,
  icons_visitorManagementPurpleHeart: icons_visitorManagementPurpleHeart,
  icons_visitorManagementVisitCard: icons_visitorManagementVisitCard,
  icons_visitorManagementVisitPointers: icons_visitorManagementVisitPointers,
  icons_visitorManagementNewInvite: icons_visitorManagementNewInvite,
  icons_visitManagementGableInSwimmingRing:
    icons_visitManagementGableInSwimmingRing,
  icons_visitorManagementGableOrchestra: icons_visitorManagementGableOrchestra,
  icons_visitorManagementPeople: icons_visitorManagementPeople,
  icons_visitorManagementHourglassTimer: icons_visitorManagementHourglassTimer,
  icons_visitorManagementQr: icons_visitorManagementQr,
  icons_visitorManagementReception: icons_visitorManagementReception,
  icons_visitorManagementVisitorImagePreview:
    icons_visitorManagementVisitorImagePreview,
  icons_visitManagementDocument: icons_visitManagementDocument,
  icons_purplePieChart: icons_purplePieChartSvg,
  icons_blackTreeNodes: icons_blackTreeNodesSvg,
  icons_blackUserAvatar: icons_blackUserAvatarSvg,
  icons_threeStackedSquaresPurple: icons_threeStackedSquaresPurpleSvg,
  icons_globe: icons_globeSvg,
  icons_balloonsWithMagnifyingGlass: icons_balloonsWithMagnifyingGlassSvg,
  icons_locationTransparent: icons_locationTransparentSvg,
  icons_gableHouseVioletDoor: icons_gableHouseVioletDoorSvg,
  icons_oneEyeCalendarWhiteFill: icons_oneEyeCalendarWhiteFillSvg,
  icons_oneEyeCalendarBulkyWhiteFill: icons_oneEyeCalendarBulkyWhiteFillSvg,
  icons_circledCheckmark: icons_circledCheckmarkSvg,
  icons_phone: icons_phoneSvg,
  icons_camera: icons_cameraSvg,
  icons_officeSingleBuilding: icons_officeSingleBuildingSvg,
  icons_SmileyWithCapSvg: icons_SmileyWithCapSvg,
  icons_gableCombinationLogo: icons_gableCombinationLogo,
  icons_contactCard: icons_contactCardSvg,
  icons_partialPieChart: icons_partialPieChartSvg,
  icons_cogWheel: icons_cogWheelSvg,
  icons_slackLogo: icons_slackLogoSvg,
  icons_draft: icons_draftSvg,
  icons_openEye: icons_openEyeSvg,
  icons_openEyeCrossedOut: icons_openEyeCrossedOutSvg,
  listSpace: listSpaceSvg,
  unlistSpace: unlistSpaceSvg,
  icons_verkada: icons_verkadaSvg,
  icons_key: icons_keySvg,
  icons_squaresWithWarning: icons_squaresWithWarningSvg,
  icons_swapSquares: icons_swapSquaresSvg,
  icons_homeProgressBar: icons_homeProgressBarSvg,
  icons_buildingsWithRadius: icons_buildingsWithRadiusSvg,
  icons_gableSpaceColorful: icons_gableSpaceColorfulSvg,
  icons_smileyWithGableMapIcon: icons_smileyWithGableMapIconSvg,
  icons_locationGrid: icons_locationGridSvg,
  icons_shieldIconWhite: icons_shieldIconWhiteSvg,
  icons_moneyBagWithForm: icons_moneyBagWithFormSvg,
  icons_threeFormsWithStar: icons_threeFormsWithStarSvg,
  icons_housePurpleCheckMark: icons_housePurpleCheckMarkSvg,
  icons_foldedMapWithGables: icons_foldedMapWithGablesSvg,
  icons_singleSeat: icons_singleSeatSvg,
  icons_paymentsIcon: icons_paymentsIconSvg,
  icons_assignBooking: icons_assignBookingSvg,
  icons_spaceCardWithHeartIcon: icons_spaceCardWithHeartIconSvg,
  icons_incognitoFace: icons_incognitoFaceSvg,
  icons_gableWithQuestionMarkColorful: icons_gableWithQuestionMarkColorfulSvg,
  mapPins_darkDot: mapPins_darkDotSvg,
  mapPins_CommonSpace_simple: mapPins_CommonSpace_simpleSvg,
  mapPins_CommonSpace_cluster: mapPins_CommonSpace_clusterSvg,
  mapPins_CommonSpace_hot: mapPins_CommonSpace_hotSvg,
  mapPins_CommonSpace_new: mapPins_CommonSpace_newSvg,
  mapPins_CommonSpace_simpleUnavailable:
    mapPins_CommonSpace_simpleUnavailableSvg,
  mapPins_CommonSpace_clusterUnavailable:
    mapPins_CommonSpace_clusterUnavailableSvg,
  mapPins_MeetingRoom_simple: mapPins_MeetingRoom_simpleSvg,
  mapPins_MeetingRoom_cluster: mapPins_MeetingRoom_clusterSvg,
  mapPins_MeetingRoom_hot: mapPins_MeetingRoom_hotSvg,
  mapPins_MeetingRoom_new: mapPins_MeetingRoom_newSvg,
  mapPins_MeetingRoom_simpleUnavailable:
    mapPins_MeetingRoom_simpleUnavailableSvg,
  mapPins_MeetingRoom_clusterUnavailable:
    mapPins_MeetingRoom_clusterUnavailableSvg,
  mapPins_PrivateOffice_simple: mapPins_PrivateOffice_simpleSvg,
  mapPins_PrivateOffice_cluster: mapPins_PrivateOffice_clusterSvg,
  mapPins_PrivateOffice_hot: mapPins_PrivateOffice_hotSvg,
  mapPins_PrivateOffice_new: mapPins_PrivateOffice_newSvg,
  mapPins_PrivateOffice_simpleUnavailable:
    mapPins_PrivateOffice_simpleUnavailableSvg,
  mapPins_PrivateOffice_clusterUnavailable:
    mapPins_PrivateOffice_clusterUnavailableSvg,
  mapPins_EventSpace_simple: mapPins_EventSpace_simpleSvg,
  mapPins_EventSpace_cluster: mapPins_EventSpace_clusterSvg,
  mapPins_EventSpace_hot: mapPins_EventSpace_hotSvg,
  mapPins_EventSpace_new: mapPins_EventSpace_newSvg,
  mapPins_EventSpace_simpleUnavailable: mapPins_EventSpace_simpleUnavailableSvg,
  mapPins_EventSpace_clusterUnavailable:
    mapPins_EventSpace_clusterUnavailableSvg,
  mapPins_CompanyOffice_simple: mapPins_CompanyOffice_simpleSvg,
  mapPins_CompanyOffice_cluster: mapPins_CompanyOffice_clusterSvg,
  mapPins_CompanyOffice_hot: mapPins_CompanyOffice_hotSvg,
  mapPins_CompanyOffice_new: mapPins_CompanyOffice_newSvg,
  mapPins_CompanyOffice_simpleUnavailable:
    mapPins_CompanyOffice_simpleUnavailableSvg,
  mapPins_CompanyOffice_clusterUnavailable:
    mapPins_CompanyOffice_clusterUnavailableSvg,
  mapPins_Generic_simple: mapPins_Generic_simpleSvg,
  mapPins_Generic_cluster: mapPins_Generic_clusterSvg,
  mapPins_Generic_simpleUnavailable: mapPins_Generic_simpleUnavailableSvg,
  mapPins_Generic_clusterUnavailable: mapPins_Generic_clusterUnavailableSvg,
};

const PNG_INTERNAL = {
  host_googleCalendar: host_googleCalendarPng,
  host_gableCalendarSync: host_gableCalendarSyncPng,
  host_gableOfficerndExchange: host_gableOfficerndExchangePng,
  host_gableNexudusExchange: host_gableNexudusExchangePng,
  marketing_companyBudgetDashboard: marketing_companyBudgetDashboardPng,
  marketing_visitorManagement: marketing_visitorManagementPng,
  eventPeopleWithSpeaker: eventPeopleWithSpeakerPng,
  icons_brivo: icons_brivoPng,
  spaceCardsWaterfallPng: spaceCardsWaterfallPng,
  spaceCardsTallWaterfallPng: spaceCardsTallWaterfallPng,
  spaceCardsCarouselPng: spaceCardsCarouselPng,
  rippling_fullLogoBlack: rippling_fullLogoBlackPng,
  rippling_rrrIcon: rippling_rrrIconPng,
  icons_locationsInOrbs: icons_locationsInOrbsPng,
};

export const SVG = SVG_INTERNAL as Record<
  keyof typeof SVG_INTERNAL,
  StaticImageData
>;

export const PNG = PNG_INTERNAL as Record<
  keyof typeof PNG_INTERNAL,
  StaticImageData
>;
