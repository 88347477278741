import { useMemo } from "react";
import useIsHost from "src/hooks/use-is-host";
import { useAuthContext } from "../../auth";

export function useGetHomePagePath(): string {
  const auth = useAuthContext();
  const isHost = useIsHost();

  return useMemo(() => {
    return auth.isNotLoggedIn ? "/login" : isHost ? "/host" : "/me/welcome";
  }, [auth, isHost]);
}
